import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Klient, KlientAlternatywny, Moduly } from '../../shared/models/klient.model';

@Injectable({
  providedIn: 'root',
})
export class KlientService {

  constructor(private http: HttpClient) { }

  getKlient(): Observable<Klient> {
    return this.http.get<Klient>('/api/klient');
  }

  getKlienciAlternatywni(): Observable<KlientAlternatywny[]> {
    return this.http.get<KlientAlternatywny[]>('/api/klient/alternatywny');
  }

  getModuly(): Observable<Moduly> {
    return this.http.get<Moduly>('/api/klient/moduly');
  }
}
