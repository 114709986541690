import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbContextMenuModule, NbButtonModule, NbIconModule, NbMenuModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { WyborKlientaComponent } from './wybor-klienta.component';


@NgModule({
  declarations: [
    WyborKlientaComponent,
  ],
  exports: [
    WyborKlientaComponent,
  ],
  imports: [
    CommonModule,
    NbMenuModule,
    NbContextMenuModule,
    NbButtonModule,
    NbIconModule,
    TranslateModule,
  ],
})
export class WyborKlientaModule { }
