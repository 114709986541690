import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormatowanieDatService {

  constructor() { }

  format(data: Date) {
    if (!data) {
      return null;
    }

    const offset = data.getTimezoneOffset();
    const date = new Date(data.getTime() - (offset * 60 * 1000));
    return date.toISOString().split('T')[0];
  }

}
