import { Component, Input, OnInit } from '@angular/core';
import { XlsExportService } from '../../../@core/services/xls-export.service';

@Component({
  selector: 'ngx-button-eksport-xls',
  templateUrl: './button-eksport-xls.component.html',
  styleUrls: ['./button-eksport-xls.component.scss'],
})
export class ButtonEksportXlsComponent implements OnInit {

  @Input()
  dane: any[];

  @Input()
  kolumny: any[];

  @Input()
  nazwa: string;

  constructor(private readonly xlsService: XlsExportService) { }

  ngOnInit() {
  }

  export() {
      this.xlsService.export(this.dane, this.kolumny, this.nazwa);
  }
}
