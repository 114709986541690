import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { Component, OnInit } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { WersjeJezykoweService } from './@core/services/wersje-jezykowe.service';

import { AnalyticsService } from './@core/utils/analytics.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService,
    private wersjeJezykowe: WersjeJezykoweService,
    private ccService: NgcCookieConsentService) {
  }

  ngOnInit() {
    this.analytics.trackPageViews();
    registerLocaleData(localePl);
    this.wersjeJezykowe.inicjalizujUstawieniaWersjiJezykowej();

    this.configureCookieConsent();

    this.wersjeJezykowe.getTranslateService().onLangChange.subscribe((event: LangChangeEvent) => {

      this.configureCookieConsent();
    });
  }

  configureCookieConsent() {
    this.wersjeJezykowe.getTranslateService()
      .get(['cookie.message', 'cookie.dismiss', 'cookie.privacy-policy-link'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.privacyPolicyLink = data['cookie.privacy-policy-link'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }
}
