import { Injectable } from '@angular/core';
import { NbOAuth2AuthStrategyOptions, NbAuthStrategyClass } from '@nebular/auth';
import { NbOAuth2AuthStrategy } from './oauth2-strategy';

@Injectable({
  providedIn: 'root',
})
export class FacebookOAuth2Strategy extends NbOAuth2AuthStrategy {

  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [FacebookOAuth2Strategy, options]; // HERE we make sure our strategy return correct class reference
  }
}
