import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbDatepickerModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
} from './components';
import { BlankLayoutComponent, OneColumnLayoutComponent, OneColumnSimpleLayoutComponent } from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { CapitalizePipe } from '../shared/pipes/capitalize.pipe';
import { PluralPipe } from '../shared/pipes/plural.pipe';
import { RoundPipe } from '../shared/pipes/round.pipe';
import { TimingPipe } from '../shared/pipes/timing.pipe';
import { KwotaPipe } from '../shared/pipes/kwota.pipe';
import { ZakresDatComponent } from '../shared/components/zakres-dat/zakres-dat.component';
import { NbInputModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WyborJezykaModule } from './components/wybor-jezyka/wybor-jezyka.module';
import { KontaktMenuModule } from './components/kontakt-menu/kontakt-menu.module';
import { ButtonEksportXlsComponent } from '../shared/components';
import { ButtonEksportPdfComponent } from '../shared/components/button-eksport-pdf/button-eksport-pdf.component';
import { WyborKlientaModule } from './components/wybor-klienta/wybor-klienta.module';
import { NgxEmptyRowDirective } from '../shared/directives/ngx-datatable-empty-row.directive';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbInputModule,
  NbEvaIconsModule,
  NbDatepickerModule,
  KontaktMenuModule,
  TranslateModule,
  WyborJezykaModule,
  WyborKlientaModule,
];
const COMPONENTS = [
  BlankLayoutComponent,
  ButtonEksportPdfComponent,
  ButtonEksportXlsComponent,
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  OneColumnSimpleLayoutComponent,
  ZakresDatComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  KwotaPipe,
];
const DIRECTIVES = [
  NgxEmptyRowDirective,
];

@NgModule({
  imports: [CommonModule, FormsModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME ],
        ).providers,
      ],
    };
  }
}
