import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WersjeJezykoweService } from '../../../../@core/services/wersje-jezykowe.service';

@Component({
  selector: 'ngx-wybor-jezyka-menu',
  templateUrl: './wybor-jezyka-menu.component.html',
  styleUrls: ['./wybor-jezyka-menu.component.scss'],
})
export class WyborJezykaMenuComponent implements OnInit, OnDestroy {

  langMenuTag = 'wyborJezyka';

  langIcons = {
    pl: 'flag-icon flag-icon-pl',
    en: 'flag-icon flag-icon-gb',
  };

  langMenu = [
    { title: 'Polski', data: 'pl' },
    { title: 'English', data: 'en' },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private menuService: NbMenuService,
    private wersjeJezykowe: WersjeJezykoweService,
  ) { }

  ngOnInit(): void {
    this.menuService.onItemClick()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(click => {
        if (click.tag === this.langMenuTag) {
          this.wersjeJezykowe.zmienJezyk(click.item.data);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLangIcon() {
    const lang = localStorage.getItem('language');
    return this.langIcons[lang];
  }

}
