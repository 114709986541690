import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Made with ♥ by <b><a href="http://nethos.pl" target="_blank">Nethos</a></b> 2021</span>
    <div class="socials">
      <a href="https://www.facebook.com/eksperciksiegowosci" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.youtube.com/channel/UCRrLUMVKqrUqDigjPwsyXNw" target="_blank" class="ion ion-social-youtube"></a>
      <a href="https://www.linkedin.com/company/2-1-biuro-rachunkowe/" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
}
