import { of as observableOf } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
    NbAuthModule, NbAuthOAuth2JWTToken,
    NbPasswordAuthStrategy,
} from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService, LayoutService } from './utils';
import { GoogleOAuth2Strategy } from './services/google-oauth2-strategy';
import { FacebookOAuth2Strategy } from './services/facebook-oauth2-strategy';

const socialLinks = [];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',

        token: {
          class: NbAuthOAuth2JWTToken,
          key: 'token',
        },
        logout: {
          endpoint: null,
        },
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
        redirectDelay: 0,
        showMessages: {
          success: false,
        },
      },
      register: {
        socialLinks: socialLinks,
      },
      validation: {
        password: {
          minLength: 6,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  GoogleOAuth2Strategy,
  FacebookOAuth2Strategy,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
