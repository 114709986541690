import { TranslateService } from '@ngx-translate/core';

export class LocaleIdService extends String {

  constructor(protected translate: TranslateService) {
    super('');
  }

  toString() {
    return this.translate.currentLang;
  }
}
