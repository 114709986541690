import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { KlientService } from '../../../@core/http/klient.service';
import { KontaktService } from '../../../@core/http/kontakt.service';
import { LayoutService } from '../../../@core/utils';
import { KlientAlternatywny } from '../../../shared/models/klient.model';
import { Kontakt } from '../../../shared/models/kontakt.model';
import { TokenPayload } from '../../../shared/models/token-payload.model';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  user: TokenPayload;
  userMenu: any;
  kontakt: Kontakt;
  opiekunGlownyTelefon: string;

  klienciAlternatywni: KlientAlternatywny[];

  public constructor(
    private authService: NbAuthService,
    private klientService: KlientService,
    private kontaktService: KontaktService,
    private layoutService: LayoutService,
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.userMenu = this.getUserMenu();
    this.translate.onLangChange.subscribe(() => this.userMenu = this.getUserMenu());

    this.authService.onTokenChange()
      .subscribe((token: NbAuthOAuth2JWTToken) => {
        if (token.isValid()) {
          this.user = token.getAccessTokenPayload();
        }
      });

    this.klientService.getKlienciAlternatywni().subscribe(klienci => {
      this.klienciAlternatywni = klienci;
    });

    this.kontaktService.getKontakt().subscribe(kontakt => {
      this.kontakt = kontakt;

      if (kontakt.opiekunGlownyTelefon) {
        this.opiekunGlownyTelefon = kontakt.opiekunGlownyTelefon;
      } else if (kontakt.opiekunGlownyTelefonKomorkowy) {
        this.opiekunGlownyTelefon = kontakt.opiekunGlownyTelefonKomorkowy;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nazwa() {
    if (this.user?.nazwa) {
      return this.user.nazwa;
    } else {
      return this.user?.email;
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  private getUserMenu() {
    return [
      {
        title: this.translate.instant('etykieta.wyloguj'),
        link: '/auth/logout',
      },
    ];
  }
}
