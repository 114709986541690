import { HttpClient, HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbDatepickerModule, NbDialogModule, NbMenuModule, NbSidebarModule, NbToastrModule, NbWindowModule } from '@nebular/theme';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { from, Observable } from 'rxjs';
import { CoreModule } from './@core/core.module';
import { AuthJWTInterceptor } from './@core/services/auth-jwt-interceptor';
import { LocaleIdService } from './@core/utils/locale-id.service';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth-guard.service';
import { PrzerwaTechnicznaGuard } from './maintenance/przerwa-techniczna/przerwa-techniczna-guard.service';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'https://online.dwaplusjeden.com/',
  },
  palette: {
    popup: {
      background: '#ffffff',
      text: '#000000',
      link: '#ffffff',
    },
    button: {
      background: '#ff3d71',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  theme: 'classic',
  type: 'info',
  layout: 'layout',
  layouts: {
    'layout': '{{messagelink}}{{compliance}}',
  },
  elements: {
    messagelink: `
    <span class="cc-message">{{message}}
      <a href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>.
    </span>
    `,
  },
  content: {
    privacyPolicyLink: 'Polityce Prywatności',
    privacyPolicyHref: 'external/polityka-prywatnosci',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    AuthGuard,
    PrzerwaTechnicznaGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthJWTInterceptor, multi: true},
    { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        return req.url === '/api/auth/refresh-token'
          || req.url === '/api/auth/refresh-token/oauth2'
          || req.url === '/api/auth/login'
          || req.url === '/api/auth/login/google'
          || req.url === '/api/auth/login/facebook'
          || req.url === '/api/auth/login/token'
          || req.url === '/api/auth/register'
          || req.url === '/api/ankieta';
    }},
    { provide: LOCALE_ID, useClass: LocaleIdService, deps: [TranslateService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
