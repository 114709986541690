import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ZakresDat } from '../../shared/models/zakres-dat.model';
import { KlientService } from '../http/klient.service';
import { FormatowanieDatService } from '../utils';
import { FontPdf } from '../utils/font-pdf';

@Injectable({
  providedIn: 'root',
})
export class PdfExportService {

  PDF_EXTENSION = '.pdf';

  constructor(
    private readonly formatowanieDat: FormatowanieDatService,
    private readonly klientService: KlientService,
  ) { }

  public export(dane: any[], kolumny: any[], kolumnyStyle: any, nazwaPliku: string, nazwaRaportu: string, zakresDat: ZakresDat) {
    this.klientService.getKlient()
      .subscribe(klient => {
        const doc = new jsPDF('landscape');
        doc.addFileToVFS('arial.ttf', FontPdf.font);
        doc.addFont('arial.ttf', 'arial', 'normal');
        doc.setFont('arial');
        doc.setLanguage('pl');

        const dataDruku: string = this.formatowanieDat.format(new Date());

        let dataOd: string = '';
        let dataDo: string = '';
        if (zakresDat) {
          dataOd = this.formatowanieDat.format(zakresDat.dataOd);
          dataDo = this.formatowanieDat.format(zakresDat.dataDo);
        }

        const numery: string[] = [];
        if (klient.nip) {
          numery.push('NIP: ' + klient.nip);
        }
        if (klient.regon) {
          numery.push('REGON: ' + klient.regon);
        }
        if (klient.krs) {
          numery.push('KRS: ' + klient.krs);
        }
        if (klient.pesel) {
          numery.push('PESEL: ' + klient.pesel);
        }

        autoTable(doc, {
          styles: {
            font: 'arial',
          },
          margin: {top: 38, bottom: 24},
          headStyles: {
            fillColor: [220, 220, 220],
            fontStyle: 'bold',
            fontSize: 12,
            textColor: [30, 30, 30],
            halign: 'center'  // default alignment for all headers
          },
          head: [[...kolumny.map(col => ({
            content: col.header,
            styles: {
              halign: col.styles?.halign || 'center'
            }
          }))]], // Override alignment per column
          columns: kolumny.map(({dataKey}) => ({dataKey})),
          columnStyles: kolumnyStyle,
          body: dane,
          didDrawPage: function (data) {
            doc.setTextColor(40, 40, 40);

            // Header
            doc.setFontSize(10);
            doc.text(klient.nazwa, 15, 9);
            doc.text(klient.adres, 15, 13);
            if (numery.length > 0) {
              doc.text(numery.join(' | '), 15, 17);
            }

            doc.text(dataDruku, doc.internal.pageSize.getWidth() - 35, 9);

            doc.setFontSize(14);
            doc.text(nazwaRaportu, doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'} );
            doc.setFontSize(10);
            if (zakresDat) {
              doc.text(dataOd + ' - ' + dataDo, doc.internal.pageSize.getWidth() / 2, 32, {align: 'center'});
            }

            // Footer
            doc.setFontSize(10);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();


            const img = new Image();
            img.src = 'assets/images/logo-white.jpg';
            doc.addImage(img, 'jpg', 15, pageHeight - 20, 11, 11);

            doc.text('DwaPlusJeden sp. z o.o.', 28, pageHeight - 17);
            doc.text('ul. Jaśkowa Dolina 6/3, 80-252 Gdańsk', 28, pageHeight - 13);
            doc.text('NIP: 9571098480 | REGON: 368476223 | KRS: 0000698730', 28, pageHeight - 9);

            doc.text(data.pageNumber.toString(), doc.internal.pageSize.getWidth() - 20, pageHeight - 9);
          },
        });

        doc.save(nazwaPliku + '_' + this.getDateFormat(new Date()) + this.PDF_EXTENSION);
    });
  }

  private getDateFormat(date: Date): string {
    return formatDate(date, 'yyyyMMdd_HHmmss', 'pl-PL');
  }
}
