import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Kontakt } from '../../../shared/models/kontakt.model';

@Component({
  selector: 'ngx-kontakt-menu',
  templateUrl: './kontakt-menu.component.html',
  styleUrls: ['./kontakt-menu.component.scss'],
})
export class KontaktMenuComponent implements OnInit {

  kontaktMenuTag = 'kontakt';

  kontaktMenu = [
    {
      title: this.translate.instant('akcja.wyslij-wiadomosc'),
      link: '/pages/kontakt',
    },
  ];

  @Input()
  kontakt: Kontakt;

  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => this.initMenu());
    this.initMenu();
  }

  initMenu() {
    this.kontaktMenu = [];

    if (this.kontakt.opiekunGlownyTelefon) {
      this.kontaktMenu.push({ title: this.translate.instant('naglowek.kontakt.opiekun-glowny') + ': ' + this.kontakt.opiekunGlownyTelefon, link: '' } );
    } else if (this.kontakt.opiekunGlownyTelefonKomorkowy) {
      this.kontaktMenu.push({ title: this.translate.instant('naglowek.kontakt.opiekun-glowny') + ': ' + this.kontakt.opiekunGlownyTelefonKomorkowy, link: '' } );
    }

    if (this.kontakt.opiekunKadryPlaceTelefon) {
      this.kontaktMenu.push({ title: this.translate.instant('naglowek.kontakt.specjalista-kadry-place') + ': ' + this.kontakt.opiekunKadryPlaceTelefon, link: '' });
    } else if (this.kontakt.opiekunKadryPlaceTelefonKomorkowy) {
      this.kontaktMenu.push({ title: this.translate.instant('naglowek.kontakt.specjalista-kadry-place') + ': ' + this.kontakt.opiekunKadryPlaceTelefonKomorkowy, link: '' });
    }

    if (this.kontakt.doradcaBiznesowyTelefon) {
      this.kontaktMenu.push({ title: this.translate.instant('naglowek.kontakt.doradca-biznesowy') + ': ' + this.kontakt.doradcaBiznesowyTelefon, link: '' });
    }

    this.kontaktMenu.push({title: this.translate.instant('akcja.wyslij-wiadomosc'), link: '/pages/kontakt' });
  }
}
