import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Kontakt } from '../../shared/models/kontakt.model';

@Injectable({
  providedIn: 'root',
})
export class KontaktService {

  constructor(private http: HttpClient) { }

  getKontakt(): Observable<Kontakt> {
    return this.http.get<Kontakt>('/api/klient/kontakt');
  }
}
