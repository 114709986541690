import { Component, Input, OnInit } from '@angular/core';
import { PdfExportService } from '../../../@core/services/pdf-export.service';
import { ZakresDat } from '../../models/zakres-dat.model';

@Component({
  selector: 'ngx-button-eksport-pdf',
  templateUrl: './button-eksport-pdf.component.html',
  styleUrls: ['./button-eksport-pdf.component.scss'],
})
export class ButtonEksportPdfComponent implements OnInit {

  @Input()
  dane: any[];

  @Input()
  kolumny: any[];

  @Input()
  kolumnyStyle: any;

  @Input()
  nazwa: string;

  @Input()
  nazwaRaportu: string;

  @Input()
  zakresDat: ZakresDat;

  constructor(private readonly pdfExport: PdfExportService) { }

  ngOnInit() {
  }

  public export() {
    this.pdfExport.export(this.dane, this.kolumny, this.kolumnyStyle, this.nazwa, this.nazwaRaportu, this.zakresDat);
  }
}
