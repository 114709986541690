import { Component, Input, OnInit } from '@angular/core';
import { KlientAlternatywny } from '../../../shared/models/klient.model';

@Component({
  selector: 'ngx-wybor-klienta',
  templateUrl: './wybor-klienta.component.html',
  styleUrls: ['./wybor-klienta.component.scss'],
})
export class WyborKlientaComponent implements OnInit {

  @Input()
  klienci: KlientAlternatywny[];

  wyborFirmyMenuTag = 'kontakt';

  wyborFirmyMenu = [
    {
      title: '',
      link: '',
    },
  ];

  constructor() { }

  ngOnInit() {
    this.wyborFirmyMenu = [];

    for (const klient of this.klienci) {
      this.wyborFirmyMenu.push({ title: klient.nazwa, link: '/auth/relogin/' + klient.id } );
    }
  }
}
