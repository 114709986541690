import { Component, OnInit } from '@angular/core';
import { WersjeJezykoweService } from '../../../../@core/services/wersje-jezykowe.service';

@Component({
  selector: 'ngx-wybor-jezyka-panel',
  templateUrl: './wybor-jezyka-panel.component.html',
  styleUrls: ['./wybor-jezyka-panel.component.scss'],
})
export class WyborJezykaPanelComponent implements OnInit {

  jezyki = [
    { kod: 'pl', etykieta: 'Polski', ikona: 'flag-icon flag-icon-pl'},
    { kod: 'en', etykieta: 'English', ikona: 'flag-icon flag-icon-gb'},
  ];

  constructor(
    private readonly wersjeJezykowe: WersjeJezykoweService,
  ) { }

  ngOnInit(): void {
  }

  zmienJezyk(kodJezyka: string): void {
    this.wersjeJezykowe.zmienJezyk(kodJezyka);
  }

}
