export class ZakresDat {

  constructor(
    readonly dataOd: Date,
    readonly dataDo: Date,
  ) { }

  isPusty() {
    return !this.dataOd && !this.dataDo;
  }
}
