import { Injectable } from '@angular/core';
import { KwotaPipe } from '../../shared/pipes/kwota.pipe';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SumowanieKwotService {

  constructor() { }

  suma(value: number[]) {
    return new KwotaPipe().transform(_.sum(value));
  }
}
