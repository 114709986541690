<div class="row">
  <div class="mr-2">
    <nb-select size="small" selected="{{ wybranyTyp }}" (selectedChange)="onChangeTyp($event)">
      <nb-option value="1">{{ 'etykieta.okres' | translate }}</nb-option>
      <nb-option value="2">{{ 'etykieta.miesiac' | translate }}</nb-option>
      <nb-option value="3">{{ 'etykieta.kwartal' | translate }}</nb-option>
      <nb-option value="4">{{ 'etykieta.rok' | translate }}</nb-option>
      <nb-option *ngIf="typWszystko" value="5">{{ 'etykieta.wszystko' | translate }}</nb-option>
    </nb-select>
  </div>

  <div *ngIf="wybranyTyp == 1">
    <span class="mr-2">
      <input nbInput fieldSize="small" placeholder="{{ 'etykieta.od' | translate }}" [nbDatepicker]="dataOdDatepicker" [ngModel]="dataOd">
      <nb-datepicker #dataOdDatepicker (dateChange)="onChangeDniDataOd($event)"></nb-datepicker>
    </span>
    <span>
      <input nbInput fieldSize="small" placeholder="{{ 'etykieta.do' | translate }}" [nbDatepicker]="dataDoDatepicker" [ngModel]="dataDo">
      <nb-datepicker #dataDoDatepicker (dateChange)="onChangeDniDataDo($event)"></nb-datepicker>
    </span>
  </div>
  <div *ngIf="wybranyTyp == 2">
    <nb-select class="mr-2" placeholder="{{ 'etykieta.miesiac' | translate }}" size="small" 
      [selected]="wybranyMiesiac" (selectedChange)="onChangeMiesiac($event)">
      <nb-option *ngFor="let miesiac of miesiace" [value]="miesiac">{{miesiac.nazwa}}</nb-option>
    </nb-select>
    <nb-select placeholder="{{ 'etykieta.rok' | translate }}" size="small" 
      [selected]="wybranyRok" (selectedChange)="onChangeMiesiacRok($event)">
      <nb-option *ngFor="let rok of lata" [value]="rok">{{rok}}</nb-option>
    </nb-select>
  </div>
  <div *ngIf="wybranyTyp == 3">
    <nb-select class="mr-2" placeholder="{{ 'etykieta.kwartal' | translate }}" size="small" 
      [selected]="wybranyKwartal" (selectedChange)="onChangeKwartal($event)">
      <nb-option *ngFor="let kwartal of kwartaly" [value]="kwartal">{{kwartal.nazwa}}</nb-option>
    </nb-select>
    <nb-select placeholder="{{ 'etykieta.rok' | translate }}" size="small" 
      [selected]="wybranyRok" (selectedChange)="onChangeKwartalRok($event)">
      <nb-option *ngFor="let rok of lata" [value]="rok">{{rok}}</nb-option>
    </nb-select>
  </div>
  <div *ngIf="wybranyTyp == 4">
    <nb-select placeholder="{{ 'etykieta.rok' | translate }}" size="small" [selected]="wybranyRok" (selectedChange)="onChangeRok($event)">
      <nb-option *ngFor="let rok of lata" [value]="rok">{{rok}}</nb-option>
    </nb-select>
  </div>
</div>
