import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class XlsExportService {

  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  constructor() { }

  public export(dane: any[], kolumny: KolumnaEksportXLS[], excelFileName: string): void {
    if (dane.length > 0) {
      const headersObject = kolumny.reduce((o, key) => Object.assign(o, {[key.dataKey]: key.header}), {});
      const headers = [];
      headers.push(headersObject);
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(headers, { skipHeader: true });

      XLSX.utils.sheet_add_json(worksheet, dane, {skipHeader: true, origin: 'A2'});

      const workbook: XLSX.WorkBook = {Sheets: {'raport': worksheet}, SheetNames: ['raport']};
      const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  private saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
    FileSaver.saveAs(data, baseFileName + '_' + this.getDateFormat(new Date())  + this.EXCEL_EXTENSION);
  }

  private getDateFormat(date: Date): string {
    return formatDate(date, 'yyyyMMdd_HHmmss', 'pl-PL');
  }
}

export interface KolumnaEksportXLS {
  header: string;
  dataKey: string;
}
