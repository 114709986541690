import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class PrzerwaTechnicznaGuard implements CanActivate {

  constructor(
    private router: Router,
  ) { }

  canActivate() {
    if (environment.maintenance) {
      this.router.navigate(['maintenance']);
      return false;
    } else {
      return true;
    }
  }

}
