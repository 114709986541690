import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth-guard.service';
import { PrzerwaTechnicznaGuard } from './maintenance/przerwa-techniczna/przerwa-techniczna-guard.service';

export const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard, PrzerwaTechnicznaGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    canActivate: [PrzerwaTechnicznaGuard],
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.NgxAuthModule),
  },
  {
    path: 'external',
    loadChildren: () => import('./external/external.module')
      .then(m => m.ExternalModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module')
      .then(m => m.MaintenanceModule),
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
