import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class WersjeJezykoweService {

  private jezykKey: string = 'language';

  constructor(private readonly translate: TranslateService) {
  }

  inicjalizujUstawieniaWersjiJezykowej(): void {
    this.translate.addLangs(['pl', 'en']);
    this.translate.setDefaultLang('pl');
    if (!this.isWersjaJezykowaZapisana()) {
      this.setUstawieniaWersjiJezykowejPrzegladarki();
    } else {
      this.setZapisaneUstawieniaWersjiJezykowej();
    }
  }

  zmienJezyk(jezyk: string): void {
    if (jezyk !== this.getJezyk()) {
      this.setJezyk(jezyk);
    }
  }

  getTranslateService(): TranslateService {
    return this.translate;
  }

  private setUstawieniaWersjiJezykowejPrzegladarki(): void {
    const browserLang = this.translate.getBrowserLang();
    const browserLangFiltered = browserLang.match(/pl|en/) ? browserLang : 'pl';
    this.setJezyk(browserLangFiltered);
  }

  private setZapisaneUstawieniaWersjiJezykowej(): void {
    this.setJezyk(this.getJezyk());
  }

  private isWersjaJezykowaZapisana(): boolean {
    return this.getJezyk() !== null;
  }

  private getJezyk(): string {
    return localStorage.getItem(this.jezykKey);
  }

  private setJezyk(kodJezyka: string): void {
    localStorage.setItem(this.jezykKey, kodJezyka);
    this.translate.setDefaultLang(kodJezyka);
    this.translate.use(kodJezyka);
  }

}
