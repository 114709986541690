<div class="header-container">
  <div class="logo-container">
    <a href="#" class="sidebar-toggle" (click)="toggleSidebar()">
      <nb-icon [icon]="'menu-2-outline'"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img class="logo-img" src="assets/images/logo-text.png">
    </a>
  </div>
</div>

<div class="header-container" *ngIf="kontakt && kontakt.opiekunGlownyImieNazwisko">
  {{ 'naglowek.kontakt.opiekun-glowny' | translate }}: {{ kontakt.opiekunGlownyImieNazwisko }} <span *ngIf="opiekunGlownyTelefon">&nbsp;({{ opiekunGlownyTelefon }})</span>
</div>

<div class="header-container">
  <ngx-wybor-jezyka-menu></ngx-wybor-jezyka-menu>
  <ngx-kontakt-menu *ngIf="kontakt" [kontakt]="kontakt"></ngx-kontakt-menu>
  <div class="mr-3"><b>{{ nazwa() }}</b></div>
  <ngx-wybor-klienta *ngIf="klienciAlternatywni?.length > 0" [klienci]="klienciAlternatywni"></ngx-wybor-klienta>
  <nb-actions size="small">
    <nb-action title="{{ 'etykieta.wyloguj' | translate }}" icon="power-outline" link="/auth/logout"></nb-action>
  </nb-actions>
  
</div>