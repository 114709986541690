import { FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NbSelectComponent } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ZakresDat } from '../../models/zakres-dat.model';

enum ZakresTyp {
  DNI = 1,
  MIESIAC = 2,
  KWARTAL = 3,
  ROK = 4,
  WSZYSTKO = 5,
}

class Miesiac {

  constructor(
    readonly nazwa: string,
    readonly numer: number,
  ) { }
}

class Kwartal {

  constructor(
    readonly numer: number,
    readonly nazwa: string,
    readonly miesiacOd: number,
    readonly miesiacDo: number,
  ) { }
}

@Component({
  selector: 'ngx-zakres-dat',
  templateUrl: './zakres-dat.component.html',
  styleUrls: ['./zakres-dat.component.scss'],
})
export class ZakresDatComponent implements OnInit {

  @Input() typWszystko: boolean = false;
  @Output() zmianaZakresu = new EventEmitter<ZakresDat>();

  @ViewChildren(NbSelectComponent) selectFields: QueryList<NbSelectComponent>;

  readonly FILTR_TYP = 'filtr_typ';
  readonly FILTR_DATA_OD = 'filtr_data_od';
  readonly FILTR_DATA_DO = 'filtr_data_do';
  readonly FILTR_MIESIAC = 'filtr_miesiac';
  readonly FILTR_KWARTAL = 'filtr_kwartal';
  readonly FILTR_ROK = 'filtr_rok';

  dataOd: Date;
  dataDo: Date;

  wybranyTyp: ZakresTyp = ZakresTyp.DNI;
  wybranyMiesiac: Miesiac;
  wybranyKwartal: Kwartal;
  wybranyRok: number;

  miesiace: Miesiac[] = [];
  kwartaly: Kwartal[] = [];
  lata: number[] = [];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.getNazwyMiesiecy();
    this.translateService.onLangChange.subscribe(() => {
      this.getNazwyMiesiecy();
      this.setDomyslnieWybraneWartosci();

      // Hack na problem przy zmianie języka w selectfield
      this.selectFields.forEach(sf => {
        sf.show();
        sf.hide();
      });
    });

    this.kwartaly = [
      new Kwartal(0, 'I', 0, 2),
      new Kwartal(1, 'II', 3, 5),
      new Kwartal(2, 'III', 6, 8),
      new Kwartal(3, 'IV', 9, 11),
    ];

    const obecnyRok = new Date().getFullYear();
    const rokGraniczny = 2000;
    for (let rok = obecnyRok; rok >= rokGraniczny; rok--) {
      this.lata.push(rok);
    }

    this.setDomyslnieWybraneWartosci();
  }

  private setDomyslnieWybraneWartosci() {
    const dzisiaj = new Date();
    const okres = new Date(dzisiaj.getFullYear(), dzisiaj.getMonth() - 1, dzisiaj.getDay());
    const obecnyRok = okres.getFullYear();

    if (localStorage.getItem(this.FILTR_DATA_OD)) {
      this.dataOd = new Date(localStorage.getItem(this.FILTR_DATA_OD));
    } else {
      this.dataOd = new Date(okres.getFullYear(), okres.getMonth(), 1);
    }

    if (localStorage.getItem(this.FILTR_DATA_DO)) {
      this.dataDo = new Date(localStorage.getItem(this.FILTR_DATA_DO));
    } else {
      this.dataDo = new Date(okres.getFullYear(), okres.getMonth() + 1, 0);
    }

    if (localStorage.getItem(this.FILTR_MIESIAC)) {
      this.wybranyMiesiac = this.miesiace[+localStorage.getItem(this.FILTR_MIESIAC)];
    } else {
      this.wybranyMiesiac = this.miesiace[okres.getMonth()];
    }

    if (localStorage.getItem(this.FILTR_KWARTAL)) {
      this.wybranyKwartal = this.kwartaly[+localStorage.getItem(this.FILTR_KWARTAL)];
    } else {
      this.wybranyKwartal = _.chain(this.kwartaly)
        .filter(function (k: Kwartal) { return k.miesiacOd <= okres.getMonth() && k.miesiacDo >= okres.getMonth(); })
        .head()
        .value();
    }

    if (localStorage.getItem(this.FILTR_ROK)) {
      this.wybranyRok = +localStorage.getItem(this.FILTR_ROK);
    } else {
      this.wybranyRok = obecnyRok;
    }

    if (this.typWszystko) {
      this.onChangeTyp(ZakresTyp.WSZYSTKO);
    } else if (localStorage.getItem(this.FILTR_TYP)) {
      this.onChangeTyp(this.getZakresTyp(+localStorage.getItem(this.FILTR_TYP)));
    } else {
      this.zmianaZakresu.emit(new ZakresDat(this.dataOd, this.dataDo));
    }
  }

  getZakresTyp(typ: number): ZakresTyp {
    switch (typ) {
      case ZakresTyp.MIESIAC:
        return ZakresTyp.MIESIAC;
      case ZakresTyp.KWARTAL:
        return ZakresTyp.KWARTAL;
      case ZakresTyp.ROK:
        return ZakresTyp.ROK;
      case ZakresTyp.WSZYSTKO:
        return ZakresTyp.WSZYSTKO;
      default:
        return ZakresTyp.DNI;
    }
  }

  onChangeTyp(typ: ZakresTyp) {
    this.wybranyTyp = typ;

    switch (+typ) {
      case ZakresTyp.MIESIAC:
        this.ustawDatyWgMiesiaca();
        break;
      case ZakresTyp.KWARTAL:
        this.ustawDatyWgKwartalu();
        break;
      case ZakresTyp.ROK:
        this.ustawDatyWgRoku();
        break;
      case ZakresTyp.WSZYSTKO:
        this.ustawDatyZakresuWszystko();
        break;
    }
  }

  onChangeDniDataOd(data: Date) {
    this.ustawDatyOdDo(data, this.dataDo);
  }

  onChangeDniDataDo(data: Date) {
    this.ustawDatyOdDo(this.dataOd, data);
  }

  onChangeMiesiac(miesiac: Miesiac) {
    this.wybranyMiesiac = miesiac;
    this.ustawDatyWgMiesiaca();
  }

  onChangeMiesiacRok(rok: number) {
    this.wybranyRok = rok;
    this.ustawDatyWgMiesiaca();
  }

  onChangeKwartal(kwartal: Kwartal) {
    this.wybranyKwartal = kwartal;
    this.ustawDatyWgKwartalu();
  }

  onChangeKwartalRok(rok: number) {
    this.wybranyRok = rok;
    this.ustawDatyWgKwartalu();
  }

  onChangeRok(rok: number) {
    this.wybranyRok = rok;
    this.ustawDatyWgRoku();
  }

  private getNazwyMiesiecy() {
    this.miesiace = [];
    getLocaleMonthNames(this.locale, FormStyle.Standalone, TranslationWidth.Wide)
      .forEach((value, idx) => this.miesiace.push(new Miesiac(value, idx)));
  }

  private ustawDatyWgMiesiaca() {
    this.ustawDaty(this.wybranyRok, this.wybranyMiesiac.numer, this.wybranyMiesiac.numer);
  }

  private ustawDatyWgKwartalu() {
    this.ustawDaty(this.wybranyRok, this.wybranyKwartal.miesiacOd, this.wybranyKwartal.miesiacDo);
  }

  private ustawDatyWgRoku() {
    this.ustawDaty(this.wybranyRok, 0, 11);
  }

  private ustawDaty(rok: number, miesiacOd: number, miesiacDo: number) {
    this.ustawDatyOdDo(new Date(rok, miesiacOd, 1), new Date(rok, miesiacDo + 1, 0));
  }

  private ustawDatyOdDo(dataOd: Date, dataDo: Date) {
    this.dataOd = dataOd;
    this.dataDo = dataDo;

    this.zmianaZakresu.emit(new ZakresDat(this.dataOd, this.dataDo));

    localStorage.setItem(this.FILTR_TYP, this.wybranyTyp.toString());
    localStorage.setItem(this.FILTR_DATA_OD, dataOd.toString());
    localStorage.setItem(this.FILTR_DATA_DO, dataDo.toString());
    if (this.wybranyMiesiac) {
      localStorage.setItem(this.FILTR_MIESIAC, this.wybranyMiesiac.numer.toString());
    }
    if (this.wybranyKwartal) {
      localStorage.setItem(this.FILTR_KWARTAL, this.wybranyKwartal.numer.toString());
    }
    if (this.wybranyRok) {
      localStorage.setItem(this.FILTR_ROK, this.wybranyRok.toString());
    }
  }

  private ustawDatyZakresuWszystko() {
    this.zmianaZakresu.emit(new ZakresDat(null, null));
  }
}
