import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WyborJezykaMenuComponent } from './wybor-jezyka-menu/wybor-jezyka-menu.component';
import { NbContextMenuModule, NbButtonModule, NbIconModule, NbMenuModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { WyborJezykaPanelComponent } from './wybor-jezyka-panel/wybor-jezyka-panel.component';



@NgModule({
  declarations: [
    WyborJezykaMenuComponent,
    WyborJezykaPanelComponent,
  ],
  exports: [
    WyborJezykaMenuComponent,
    WyborJezykaPanelComponent,
  ],
  imports: [
    CommonModule,
    NbMenuModule,
    NbContextMenuModule,
    NbButtonModule,
    NbIconModule,
    TranslateModule,
  ],
})
export class WyborJezykaModule { }
