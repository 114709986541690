// https://github.com/swimlane/ngx-datatable/issues/1367#issuecomment-511893170
import { Directive, Renderer2, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';

/**
 * Directive to add an empty row to `ngx-datatable components that do not have any data.
 * This fixes a bug with ngx-datatable in which empty tables do not have a scrollable header.
 */
@Directive({
    selector: '[ngxDatatableEmptyRow]',
})
export class NgxEmptyRowDirective implements AfterViewInit, AfterViewChecked {
    private datatableBodyEl: HTMLElement;
    private datatableHeaderEl: HTMLElement;
    private datatableEmptyRowEl: HTMLElement;
    private datatableRowCenterEl: HTMLElement;

    constructor(private renderer: Renderer2, private hostElRef: ElementRef) { }

    public ngAfterViewInit() {
        this.setUpElementReferences();
        if (this.shouldApplyHack) {
            this.hackEmptyCellWidth();
            this.setUpHeaderScrollListener();
        }
    }

    public ngAfterViewChecked() {
        if (this.shouldApplyHack) {
            this.hackEmptyCellWidth();
        }
    }

    private setUpHeaderScrollListener() {
        this.renderer.listen(this.datatableBodyEl, 'scroll', (event: any) => {
            if (this.shouldApplyHack) {
                this.datatableHeaderEl.scrollLeft = event.srcElement.scrollLeft;
            }
        });
    }

    private hackEmptyCellWidth() {
        const newWidth = this.datatableRowCenterEl.style.width;
        this.renderer.setStyle(this.datatableEmptyRowEl, 'width', newWidth);
    }

    private setUpElementReferences() {
        const hostEl = this.hostElRef.nativeElement;
        this.datatableBodyEl = hostEl.getElementsByClassName('datatable-body')[0];
        this.datatableHeaderEl = hostEl.getElementsByClassName('datatable-header')[0];
        this.datatableEmptyRowEl = hostEl.getElementsByClassName('empty-row')[0];
        this.datatableRowCenterEl = hostEl.getElementsByClassName('datatable-row-center')[0];
    }

    private get shouldApplyHack(): boolean {
        const hostEl = this.hostElRef.nativeElement;
        this.datatableEmptyRowEl = hostEl.getElementsByClassName('empty-row')[0];
        return !!this.datatableEmptyRowEl;
    }
}
